@font-face {
    font-family: 'Calibri';
    src: url('./fonts/Calibri Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('./fonts/Calibri Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('./fonts/Calibri Bold.TTF');
    font-weight: 700;
    font-style: normal;
}
