body {
    font-family: 'Calibri', serif;
    font-weight: 400;
    background: #ffffff;
}

a {
    text-decoration: none;
}

.main-logo {
    margin-bottom: 30px;
}


/* Registration form comment/Remark field*/
.registration-form-remark-field{
    width: 100% !important;
    height: auto !important;
    margin-top: 6px;
}

.registration-form-remark-field textarea {
    min-height: 70px !important;
    padding: 6px 16px !important;
}


.main-admin {
    width: 100%;
    float: left;
    height: 100%;
    background: #ffffff;
}

/* Buttons Style */

.default-button-style {
    width: 280px;
}

.default-button-style button:hover {
    border: 1px solid #2962FF;
    background: transparent;
    color: #2962FF;
}

.default-button-style button .ms-Button-label {
    font-size: 16px;

}

.options-card {
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    margin: 15px;
    padding: 30px 17px;
}

.options-card span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Calibri', serif;
    margin-bottom: 20px;
    display: block;
}

.option-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.option-desc p {
    min-height: 48px;
    border: 1px solid #0A66C2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: rgba(10, 102, 194, 0.9);
    font-family: 'Calibri', serif;
    margin-bottom: 4px;
    margin-top: 0;
    width: 100%;
    cursor: pointer;
    padding: 5px;
}

.option-desc p:hover {
    background: #EAF4FF;
}

.options-card p.days-left {
    width: 500px;
    height: 17px;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: -17px;
}

.buttons-groups {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.buttons-groups button {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 175px;
    background: transparent;
    color: #2962FF;
    padding: 0;
    margin: 16px 10px 0px 10px;
}

/* .buttons-groups button:hover {
    color: #ffffff;
    background: #3654a5;
} */

.buttons-groups button .ms-Button-label {
    font-size: 16px;
    font-family: 'Calibri', serif;
    font-weight: 700;
}

.import-buttons button {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    width: 83px;
    background: transparent;
    color: #2962FF;
    padding: 0;
}

.export-buttons button {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    width: 83px;
    background: transparent;
    color: #2962FF;
    padding: 0;
}

/* .import-buttons button:hover {
    color: #ffffff;
    background: #2962FF;
} */

/* Header Style */
.header-links {
    width: 100%;
    padding-left: 40px;
    display: flex;
    align-items: center;
}

.links-col {
    padding-right: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.links-col a {
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    font-family: 'Calibri', serif;
    line-height: 120%;
}

/* Admin Style */
.adminlogin-left {
    width: 30%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-heading {
    width: 280px;
    margin-bottom: 26px;
    text-align: center;
}

.forgot-heading {
    width: 340px;
    margin-bottom: 24px;
}

.button-with-arrow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.button-with-arrow button {
    height: 48px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2962FF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    font-family: 'Calibri', serif;
    cursor: pointer;
}

.button-with-arrow button:hover {
    background: #3654a5;
}

/* Default input style */
.default-input {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.default-input.error {
    border: 1px solid rgb(152, 1, 76);
}

span.combine-error {
    color: rgb(152, 1, 76);
    font-size: 13px;
    line-height: 22px;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

.default-input input {
    height: 46px;
    border: none;
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 0;
}

.default-input img {
    width: 24px;
    margin-right: 15px;
}

.default-input input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9CA3AF;
}

.default-input input::-moz-placeholder {
    /* Firefox 19+ */
    color: #9CA3AF;
}

.default-input input:-ms-input-placeholder {
    /* IE 10+ */
    color: #9CA3AF;
}

.default-input input:-moz-placeholder {
    /* Firefox 18- */
    color: #9CA3AF;
}

.set-height .form-group {
    height: 72px;
}

.file-uploader input {
    height: auto !important;
    padding: 8px 0 !important;
}

.file-uploader input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

.rdw-image-imagewrapper img {
    width: 100% !important;
}

.input-with-label label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
    margin-bottom: 2px;
    display: block;
}

.form-group.subject .input-with-label label {
    margin-bottom: 3px;
}

.input-with-label-error label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
    margin-bottom: 2px;
    display: block;
}

.input-with-label input {
    height: 38px;
    border: 1px solid #4242425c;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    text-indent: 15px;
    background: transparent;
    box-shadow: none;
}

.input-with-label-error input {
    height: 38px;
    border: 1px solid rgb(152, 1, 76);
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    text-indent: 15px;
    background: transparent;
    box-shadow: none;
}

.input-with-label-error span {
    color: rgb(164, 38, 44);
    font-size: 13px;
    line-height: 22px;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

.with-icon-input {
    height: 38px;
    border: 1px solid #4242425c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-radius: 4px;
}

.with-icon-input input {
    border: none;
    height: 38px;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    background: transparent;
    box-shadow: none;
}

.with-icon-input img {
    width: 22px;
    height: 22px;
}

.icon-input label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
    margin-bottom: 2px;
    display: block;
}

.with-icon-input-error {
    height: 38px;
    border: 1px solid rgb(152, 1, 76);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-radius: 4px;
}

.with-icon-input-error input {
    border: none;
    height: 38px;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    background: transparent;
    box-shadow: none;
}

.icon-input span {
    color: rgb(164, 38, 44);
    font-size: 13px;
    line-height: 22px;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

/* Description Style */
.defalut-dash-description {
    width: 70%;
    margin: 0;
}

/* aside bar style */
.aside-bar {
    width: 233px;
    background: #FFFFFF;
    float: left;
    height: calc(100vh - 60px);
    overflow-y: auto;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    margin-top: 60px;
}

.aside-bar ul {
    margin: 0;
    padding: 0;
}

.aside-bar ul li {
    margin: 0;
    padding: 0 15px;
}

.aside-bar ul li a {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
}
.aside-bar ul ul li a {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
}

.aside-bar ul  ul li a p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Calibri', serif;
    color: #424242;
    margin:0;
}

.aside-bar ul li a span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Calibri', serif;
    color: #424242;
}

.aside-bar ul li a img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
}
.aside-bar ul li a .drop-icons img {
    margin-right: 0px;
    width: 20px;
    height: 20px;
}

.aside-bar ul li.nav-item.active a span {
    color: #2962FF;
}

li.nav-item1.active {
    border-right: 2px solid  #2962FF;
}

.aside-bar ul li.nav-item1.active a span {
    color: #2962FF;
}

.aside-bar ul li a .nav-icons {
    display: flex;
}

.aside-bar ul li.nav-item.active a .inactive-icon {
    display: none;
}

.aside-bar ul li.nav-item.active a .active-icon {
    display: flex;
}

.aside-bar ul li.nav-item a .active-icon {
    display: none;
}

.aside-bar ul li.nav-item1.active a .inactive-icon {
    display: none;
}

.aside-bar ul li.nav-item1.active a .active-icon {
    display: flex;
}

.aside-bar ul li.nav-item1 a .active-icon {
    display: none;
}

.aside-bar ul li.nav-item1.active a .inactive-icon1 {
    display: none;
}

.drop-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


/* Form Style */
.add-user-form {
    width: 100%;
    float: left;
    margin-top: 27px;
}

.add-user-form-new {
    width: 100%;
    float: left;
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mailto-text {
    margin-right: 15px;
}

.mailto-text span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #424242;
    font-family: 'Calibri', serif;
}

.form-editor {
    width: 600px;
    float: left;
    margin-bottom: 12px;
}

.form-editor label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
    margin-bottom: 2px;
    display: block;
}

.form-editor textarea {
    height: 90px;
    border: 1px solid #4242425c;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 15px 0 0 15px;
    border-radius: 4px;
    background: transparent;
    box-shadow: none;
    margin-bottom: 20px;
}

.choice-box {
    display: flex;
    align-items: center;
}

.radio-buton {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.radio-buton input {
    margin-top: 0;
}

.radio-buton span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #201F1E;
    font-family: 'Calibri', serif;
}

.add-user-form .form-group {
    width: 48%;
    float: left;
    margin-bottom: 13px;
    height: 72px;
}

.add-user-form .form-group.error {
    height: 86px;
}

.add-user-form .form-group-textarea {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding: 0 32px 0 0;
}

.add-user-form h4 {
    font-family: 'Calibri', serif;
    margin-bottom: 7px;
}

.permanent-box {
    width: 97%;
    float: left;
}

.drop-with-icon {
    position: relative;
}

span.ms-Dropdown-caretDownWrapper {
    height: 36px !important;
    line-height: 36px !important;
}

span.ms-Dropdown-caretDownWrapper i.ms-Dropdown-caretDown {
    font-size: 14px !important;
    color: #000000 !important;
}

.drop-with-icon img {
    position: absolute;
    right: 15px;
    top: 35px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.add-user-form .form-group-textarea .check-group label {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.permanent-box .check-group label span {
    font-size: 14px;
}

.add-user-form .form-group textarea {
    height: 36px;
    border: 1px solid #4242425c;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    padding: 6px 0 0 15px;
    border-radius: 4px;
    background: transparent;
    box-shadow: none;
    resize: auto;
    row-gap: 10px;
    width: 97% !important;
}

.add-user-form .form-group-textarea textarea {
    height: 40px;
    border: 1px solid #4242425c;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 0px 0 0 0;
    border-radius: 4px;
    text-indent: 15px;
    background: transparent;
    box-shadow: none;
}

.add-user-form .form-group-new {
    width: 23%;
    float: left;
    margin-bottom: 0px;
    padding: 0 8px;
}

.add-user-form .form-group label.ms-Label.ms-Dropdown-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
    padding: 0;
    margin-bottom: 2px;
}

span.ms-Dropdown-titleIsPlaceHolder {
    color: #42424275 !important;
}

.add-user-form .form-group span.ms-Dropdown-title {
    height: 38px;
    border: 1px solid #4242425c;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
    outline: none;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    text-indent: 15px;
    display: flex;
    align-items: center;
    background: transparent;
    box-shadow: none;
}

.ms-Button-label {
    font-size: 16px;
    font-family: 'Calibri', serif;
    font-weight: normal;
}

.next-button button {
    background: #2962FF;
    border-radius: 4px;
    height: 40px;
    width: auto;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
}

.next-button button:hover {
    background: #3654a5;
}

.next-button button span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    font-family: 'Calibri', serif;
}

.next-button button img {
    margin-left: 10px;
}

.add-more-field {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.add-more-field button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: auto;
    width: auto;
    text-align: center;
    color: #2962FF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Calibri', serif;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.add-more-field button .sl-bubble1 .sl-loader {
    width: auto;
}

.add-more-field button img {
    width: 20px;
    margin-right: 10px;
}

.form-group-checkbox {
    width: 23%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
    margin-bottom: 10px;
}

.form-group-checkbox input {
    border: 1px solid #E0E0E0;
}

.form-group-checkbox span.ms-Checkbox-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    font-family: 'Calibri', serif;
}

/* Tabs Style */
.user-tabs {
    width: 100%;
    float: left;
    margin-top: 10px;
}

.user-tabs button.ms-Pivot-link {
    padding: 0px;
    margin-right: 20px;
}

.user-tabs .ms-Pivot-link.is-selected::before {
    background-color: #2962FF;
}

.Userfilters button.ms-Pivot-link {
    padding: 0px;
    margin-right: 0px;
    width: 100%;
    text-align: left;
    border-right: 1px solid rgb(224, 224, 224);
}

.Userfilters .ms-Pivot-link.is-selected {
    border-right: 1px solid #2962FF;
}

.Userfilters .ms-Pivot-link::before {
    background-color: transparent !important;
}

.Userfilters .ms-Pivot-link.is-selected::before {
    background-color: transparent !important;
}

.Userfilters span.ms-Pivot-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    color: #424242;
    font-family: 'Calibri', serif;
}

.Userfilters .ms-Pivot-link:hover {
    background-color: transparent;
}

.Userfilters .is-selected .ms-Pivot-text {
    color: #2962FF;
}

.user-tabs .is-selected .ms-Pivot-text {
    color: #2962FF;
}

.user-tabs .is-selecteds:hover {
    color: #424242;
    background-color: transparent;
}

.user-tabs .ms-Pivot-link::before {
    background-color: #E0E0E0;
}

.user-tabs .ms-Pivot-link:hover {
    background-color: transparent;
    cursor: auto;
}

.user-tabs span.ms-Pivot-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #424242;
    font-family: 'Calibri', serif;
}

.forms-checkboxes {
    width: 100%;
    float: left;
}

.check-group {
    display: flex;
    flex-direction: column;
}

.check-group label {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.check-group label span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #201F1E;
    font-family: 'Calibri', serif;
    margin-left: 10px;
}

.check-group label input {
    width: 16px;
    height: 16px;
    margin: 0;
}

.search-input {
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    width: 245px;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.search-input img {
    margin-right: 15px;
}

.search-input input {
    padding: 0 0 6px;
    width: 100%;
    height: 36px;
    outline: none;
}

.search-input .ms-SearchBox-iconContainer {
    display: none;
}

.border-space {
    width: 100%;
    float: left;
    padding: 0 0 15px 0;
    margin-top: 15px;
}

/*Table Style*/
.fluent-table {
    padding: 34px 31px 0 31px;
}

.fluent-table .ms-FocusZone.ms-DetailsHeader {
    background: #E3F2FD;
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: none;
}

.fluent-table .ms-DetailsHeader-cell:hover {
    background: transparent;
    color: #424242;
}

.fluent-table span.ms-DetailsHeader-cellName {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 14px;
    color: #424242;
}

.fluent-table .ms-DetailsRow-cell {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 15px;
    color: #424242;
    height: 50px;
    display: flex;
    align-items: center;
}

.fluent-table .ms-List-page .ms-List-cell:nth-of-type(odd) .ms-DetailsRow {
    background-color: #ffffff;
    border-bottom: none;
}

.fluent-table .ms-List-page .ms-List-cell:nth-of-type(even) .ms-DetailsRow {
    background-color: #F5F5F5;
    border-bottom: none;
}

.Userfilters {
    margin-top: 22px !important;
    width: 100%;
    float: left;
}

.manage-column {
    width: 100%;
    padding: 16px 0 16px 0;
    float: left;
}

.cross-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.table-columns {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    margin-top: 15px;
}

.table-columns .check-group {
    width: 50%;
    float: left;
    margin-bottom: 10px;
}

.table-columns .check-group label span {
    color: #2962FF;
}

.table-columns .check-group label input {
    outline: none;
}

.column-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 28px 0 0 0;
    float: left;
}

.column-buttons .cancel-btn {
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 4px;
    min-width: 80px;
    height: 36px;
    color: #000000;
    background: #ffffff;
    margin-right: 10px;
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    font-family: 'Calibri', serif;
    font-weight: 700;
    border: 1px solid #D0D0CE;
}

.column-buttons .cancel-btn:hover {
    background: #F8F8F8;
}

.column-buttons .apply {
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 4px;
    min-width: 80px;
    height: 36px;
    color: #ffffff;
    background: #2962FF;
    user-select: none;
    font-size: 14px;
    font-family: 'Calibri', serif;
    font-weight: normal;
    border: none;
}

.column-buttons button.apply:hover {
    background: #3654a5;
}

.column-buttons .delete {
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 4px;
    min-width: 80px;
    height: 36px;
    color: #ffffff;
    background: #E61E00;
    user-select: none;
    font-size: 14px;
    font-family: 'Calibri', serif;
    font-weight: 700;
    border: none;
}

.fluent-table-v2 {
    padding: 20px 31px 0 31px;
    max-height: 600px;
    overflow-y: auto;
}

.fluent-table-v2 table {
    width: 100%;
    border-spacing: 0px;
    border: 1px solid #33333312;
    border-top: none;
}

.fluent-table-v2 table thead {
    background: #E3F2FD;
}

.fluent-table-v2 table thead tr th {
    text-align: left;
    padding: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Calibri', serif;
    color: #424242;
}

.fluent-table-v2 table tbody tr td {
    text-align: left;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    font-family: 'Calibri', serif;
    color: #424242;
}

.fluent-table-v2 table tbody tr td ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.fluent-table-v2 table tbody tr td ul li {
    display: flex;
    align-items: center;
    padding: 0px 20px 0 0px;
    margin: 0;
}

.fluent-table-v2 table tbody tr:nth-child(even) {
    background: #ffffff;
}

.fluent-table-v2 table tbody tr:nth-child(odd) {
    background: #F5F5F5;
}

.fluent-table-v2 table tbody tr td ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fluent-table-v2 table thead tr th input[type="checkbox"] {
    background-color: transparent;
}

.avatar-pic {
    padding: 0 0 0 0 !important;
}

.avatar-pic img {
    width: 24px;
}

.account-setting-drop {
    position: absolute;
    padding: 0px;
    width: 234px;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    top: 60px;
    right: 15px;
    box-shadow: rgb(0 0 0 / 10%) 2px 2px 50px;
    z-index: 999;
}

.account-setting-drop a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 45px;
    border-bottom: 0.5px solid rgba(196, 193, 193, 0.3);
    text-decoration: none;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0px 32px;
}

.account-setting-drop a img {
    width: 22px;
    height: 22px;
}

.account-setting-drop a span {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 16px;
    color: rgb(68, 68, 68);
    padding-left: 15px;
    display: block;
}

.account-setting-drop button {
    border: none;
    height: 45px;
    color: rgb(89, 100, 117);
    text-transform: capitalize;
    margin: 0px;
    border-radius: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0px 32px;
    background: transparent !important;
    cursor: pointer;
}

.account-setting-drop button img {
    width: 22px;
    height: 22px;
}

.account-setting-drop button span {
    font-style: normal;
    font-family: 'Calibri', serif;
    font-size: 16px;
    color: rgb(68, 68, 68);
    padding-left: 15px;
    display: block;
}

.notification-drop {
    position: absolute;
    padding: 0px;
    width: 440px;
    border-radius: 0px;
    top: 60px;
    right: 50px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.notify-heading {
    padding: 15px 22px 0 22px;
}

.notify-heading p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin: 0;
}

.notify-drop-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 22px;
    border-bottom: 1px solid #F2F2F2;
}

.notify-left-box {
    display: flex;
}

.notification-by {
    padding-left: 12px;
}

.info-notify {
    display: flex;
}

.info-notify h5 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.info-notify p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-left: 3px;
}

.info-notify span {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-left: 3px;
    padding-right: 3px;
}

.notify-created-date p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #A5ACB8;
}

.name-info-show {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.name-info-show span {
    background: #DDDEE1;
    border-radius: 6px;
    width: 4px;
    height: 29px;
}

.firstlastname-info {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.firstlastname-info p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A5ACB8;
    margin:0;
}
.firstlastname-info b {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin:0;
}

h4.change-password {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #424242;
    font-family: 'Calibri', serif;
    margin: 15px 0 0 0;
}

.notify-view-detail span {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2962FF;
}

.notify-show-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}

.notify-show-more button {
    border: none;
    height: auto;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2962FF;
    cursor: pointer;
}

.rdw-editor-main {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    border: 1px solid #4242425c;
    border-radius: 4px;
    height: 80px;
    padding: 0 15px;
    border-top: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px;
}

.rdw-editor-toolbar {
    border-radius: 4px !important;
    border: 1px solid #4242425c !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.search-input .ms-SearchBox.is-active:after {
    border: none !important;
}

.add-user-form .form-group .drop-error span.ms-Dropdown-title {
    border: 1px solid rgb(152, 1, 76);
}

.no-records-intable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 0;
}

.no-records-intable span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #424242;
    font-family: 'Calibri', serif;
    display: block;
    margin-top: 32px;
}

.no-records-intable p {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6F727A;
    margin: 10px 0 0 0;
}

.intial-box {
    width: 30px;
    height: 30px;
    background: #2962FF;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intial-box p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Calibri', serif;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
}

.delete-buton {
    border: 1px solid rgb(164, 38, 44);
    height: 34px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0 0;
    width: auto;
    padding: 0 15px;
    cursor: pointer;
}

.delete-buton span {
    color: rgb(164, 38, 44);
    font-weight: 400;
    font-size: 16px;
    font-family: 'Calibri', serif;
}

.error {
    color: rgb(164, 38, 44);
    font-weight: 400;
    font-size: 14px;
    font-family: 'Calibri', serif;
}

.login-buton .next-button button {
    width: 100%;
}

.setting-button {
    height: 34px;
    width: 34px;
    border: 1px solid #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.setting-button img {
    width: 24px;
    height: 24px;
}

.choose-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #212121;
    font-family: 'Calibri', serif;
    margin-bottom: 31px;
    display: block;
}

.select-school {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    height: 50px;
    width: 230px;
    padding: 0 24px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 10%), 0px 0px 4px rgb(0 0 0 / 10%);
}

.select-school span {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #000000;
    font-family: 'Calibri', serif;
}

.select-school:hover {
    border: 1px solid #2962FF;
}

.ms-Dropdown-container.drop-error.actual-height {
    height: 62px;
}

.ms-Dropdown-container.drop-error.actual-height div#Dropdown25-errorMessage {
    padding-top: 0px;
}

.form-group.current {
    height: 85px;
}

.csv-impoter-screen {
    padding: 32px;
}

.CSVImporter_FileSelector {
    border: 2px dashed #2962FF !important;
    background: #2962ff0d !important;
    color: #2962FF !important;
}

.CSVImporter_FormatRawPreview {
    display: none;
}

.CSVImporter_FileStep__header {
    font-size: 16px !important;
    color: grey !important;
    font-family: 'Calibri', serif;
    font-style: normal;
    font-weight: 400;
}

.CSVImporter_FileStep__headerToggle {
    font-size: 16px !important;
    color: grey !important;
    font-family: 'Calibri', serif;
    font-style: normal;
    font-weight: 400;
}

.CSVImporter_TextButton {
    background: #2962FF !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    border: none !important;
    font-family: 'Calibri', serif;
    font-style: normal;
    font-weight: 400;
}

.CSVImporter_ColumnDragTargetArea__boxLabel {
    font-family: 'Calibri', serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.CSVImporter_ColumnDragCard__cardHeader>b {
    background: #2962FF !important;
    color: #ffffff !important;
}

.CSVImporter_ColumnDragCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.CSVImporter_ColumnDragCard__cardHeader {
    margin-right: unset !important;
    margin-left: unset !important;
}

.CSVImporter_ColumnDragCard__cardValue {
    padding: 0 10px !important;
}

.CSVImporter_IconButton>span[data-type=close] {
    background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E%0A) !important;
}

.import-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    flex-direction: column;
}

.import-confirmation span {
    color: #6F727A;
    font-family: 'Calibri', serif !important;
    font-style: normal;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
}

.import-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.import-buttons .import-cancel {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 175px;
    background: transparent;
    font-size: 16px;
    font-family: 'Calibri', serif;
    font-weight: 700;
    color: #2962FF;
    padding: 0;
    margin: 25px 10px 0px 10px;
}

.import-confirmation button.import-yes {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 175px;
    background: transparent;
    font-size: 16px;
    font-family: 'Calibri', serif;
    font-weight: 700;
    color: #2962FF;
    padding: 0;
    margin: 25px 0px 0px 0px;
}

span.estirc-sign {
    color: rgb(164, 38, 44);
}

.edu-error {
    color: rgb(164, 38, 44) !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

.custom-form-field .form-group label.ms-Label.ms-Dropdown-label:after {
    display: none;
}


/* Unsubscribe Style */
.unsubscribe-box {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background: #ffffff;
}

.unsubscribe-content {
    width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.unsubscribe-content.successful {
    width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.unsubscribe-content span {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    font-family: 'Calibri', serif;
    color: #000000;
}

.unsubscribe-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    font-family: 'Calibri', serif;
    color: #000000;
    margin: 15px 0 0 0;
}

.unsubscribe-form {
    width: 100%;
    float: left;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-group-unsubscribe {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.checkbox-group-unsubscribe label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.checkbox-group-unsubscribe label input {
    width: 18px;
    height: 18px;
}

.checkbox-group-unsubscribe label span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #424242;
    font-family: 'Calibri', serif;
    margin-left: 18px;
    display: block;
}

.unsubsscri-button {
    width: 320px;
    float: left;
    margin-left: 10px;
    margin-top: 40px;
}

.unsubsscri-button button {
    background: #424242;
    border: 1px solid #424242;
    height: 50px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Calibri', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
}

.unsubscribe-content a {
    margin-top: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Calibri', serif;
    text-decoration-line: underline;
    color: #424242;
    display: block;
    width: 260px;
    margin: 15px auto 0 auto;
}

.email-preview img {
    width: 100% !important;
}

.buttonGroupStyles {
    padding-left: 30px;
    padding-top: 20px;
}

.button-style {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.button-style button {
    width: 220px;
    height: 40px;
    background: #ffffff;
    color: #1D2939;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Calibri', serif;
    border: 1px solid #D0D5DD;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
}

.button-style button.active {
    background-color: #2962FF;
    color: white;
    border: 1px solid #2962FF;
}

.button-style button.acount-settings {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.button-style button.acount-settings:hover {
    background-color: #f5f5f5 !important;
    color: black;
    border: 1px solid #f5f5f5;
}

.button-style button.emailcamp-button:hover {
    background-color: #f5f5f5 !important;
    color: black;
    border: 1px solid #f5f5f5;
}

.button-style button.email-category-button:hover {
    background-color: #f5f5f5 !important;
    color: black;
    border: 1px solid #f5f5f5;
}

.button-style button.acount-settings.active.hover {
    background-color: #2962FF;
    color: black;
}

.button-style button.emailcamp-button.active.hover {
    background-color: #2962FF;
    color: black;
}

.button-style button.email-category-button.active.hover {
    background-color: #2962FF;
    color: black;
}

.email-category-button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.button-style button:hover {
    background-color: rgb(41, 98, 255) !important;
}



.delete-columns p {
    margin: 6px 0px 0px 0px;
    color: #6A6A65;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    font-family: 'Calibri', serif;
    max-width: 300px;
}

.delete-columns span {
    color: #6A6A65;
    font-weight: 400 !important;
    font-size: 16px !important;
    font-style: normal;
    font-family: 'Calibri', serif;
}

.ms-Dialog-header {
    display: none;
}

.modal-heading {
    margin-top: 10px;
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.modal-heading span {
    font-family: 'Calibri', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.new-optional-field h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #424242;
    font-family: 'Calibri', serif;
    margin: 0;
}

.new-optional-field p {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 14px;
    line-height: 24px;
    color: #6F727A;
    margin: 3px 0 0 0;
}

.new-radio-button {
    width: 100%;
    float: left;
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 26px;
}

.radio-text {
    width: 40%;
    float: left;
}

.radio-text span {
    color: #424242;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: 'Calibri', serif;
}

.radio-text p {
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 14px;
    line-height: 20px;
    color: #6F727A;
    margin: 0;
}

.new-radio-input .radio-group {
    margin-bottom: 10px;
}

.radio-group {
    display: flex;
    align-items: center;
}

.radio-group input {
    margin: 0;
    width: 17px;
    height: 17px;
}

.new-radio-input .radio-group span {
    color: #424242;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Calibri', serif;
    margin-left: 7px;
}

.pagination-ui {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px 20px 32px;
}

.select-items-pagination {
    border: 1px solid #D0D5DD;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 16px;
}

.select-items-pagination span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Calibri', serif;
    color: #424242;
    padding-right: 16px;
    border-right: 1px solid #D0D5DD;
    height: 40px;
    display: flex;
    align-items: center;
}

.select-items-pagination select {
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding-left: 16px;
    outline: none;
    color: #424242;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Calibri', serif;
}

.pagination-ui ul.pagination {
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    height: 40px;
    padding: 0px;
}

.pagination-ui ul.pagination li {
    border-right: 1px solid #D0D5DD;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;
}


.pagination-ui ul.pagination li.page-item.active {
    background: #F9FAFB;
}

.pagination-ui ul.pagination li a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Calibri', serif;
    color: #424242;
    cursor: pointer;
}

.pagination-ui ul.pagination li.page-item.disable {
    background: #EEEEEE;

}

.pagination-ui ul.pagination li.page-item.disable a .arrows-page span {
    color: #C8C8C8;

}

li.page-item.border-radius-right.disable {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

li.page-item.border-radius-left.disable {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrows-page {
    display: flex;
    align-items: center;
}

.arrows-page span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Calibri', serif;
    color: #424242;
}

.multiple-new-upload {
    border: 1px dashed #4242425c;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.multiple-new-upload.new-attchment {
    border: none;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.multiple-new-upload img {
    cursor: pointer;
}

.multiple-new-upload.new-attchment input {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px dashed #4242425c;
    height: 54px !important;
    padding: 0px 0 0px 0 !important;
    text-indent: 9px;
    border-radius: 4px;
    background-position: center 14%;
    background-size: 24px;
    background-repeat: no-repeat !important;

}

.multiple-new-upload.new-attchment img {
    cursor: pointer;
    position: absolute;
    margin-top: -10px;
}

.multiple-new-upload input {
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
}

.custom-file-input {
    color: transparent;
    font-size: 0px !important;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Click to upload photos';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Calibri', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.custom-file-input:active {
    outline: 0;
}

.uploaded-images-list {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    float: left;
}

.uploaded-images-col {
    width: 257px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-radius: 6px;
    position: relative;
}

.uploaded-images-col img {
    width: 100%;
    border-radius: 6px;
}

.remove-uploaded-image {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
}

.uploader-image-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000047;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

button.poll-buton {
    height: 40px;
    width: 124px;
    border: 1px solid #2962FF;
    background: transparent;
    color: #2962FF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-family: 'Calibri', serif;
    font-size: 16px;
}

button.poll-buton img {
    margin-right: 10px;
}

.remove-option {
    background: transparent;
    border: none;
    color: #2962FF;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Calibri', serif;
}

.edit-icon-contact {
    padding-left: 8px;
}

.remove-attachment-cross {
    margin-left: 18px;
    cursor: pointer;
}

.registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-registration-form {
    width: 45%;
}

a.approve-link {
    color: green;
    cursor: pointer;
}

a.reject-link {
    color: red;
    cursor: pointer;
}

p.approve-link {
    color: green;
}

p.reject-link {
    color: red;
}

p.view-link {
    color: lightskyblue;
}

span#Dropdown0-option {
    background-color: #f3f2ef;
}

span#Dropdown1-option {
    background-color: #f3f2ef;
}

span#Dropdown2-option {
    background-color: #f3f2ef;
}

span#Dropdown3-option {
    background-color: transparent ;    
}

.form-page {
    margin: 20px;
}

.registtration-form-content {
    width: 80%;
    float: left;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.registtration-form {
    width: 80%;
    float: left;
}

input[type='date'] {
    text-indent: 9px !important;
}

.ms-Callout-main {
    max-height: 40vh !important;
}

.modal-heading{
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.member-info-new-details-with-icon
{
    display: flex;
}

img.social-icon {
    padding-right: 10px;
}

li.nav-item {
    list-style: none;
}

span.drop-downValue {
    padding-left: 40px;
}

.modal-description p {
    word-wrap: break-word;
    max-width: 416px;
    max-height: 160px;
    overflow-y: scroll;
}

.modal-description p {
    word-wrap: break-word;
    max-width: 416px;
    max-height: 160px;
    overflow-y: auto;
}

input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}

input[type='date']::before {
    content: '';
    color: #555;
    background-repeat: no-repeat;
    background-image: url('./images/calendar.svg');
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: 0px;
}

input[type='date'] {
    position: relative;
}


/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
    z-index: 1;
}

.modal-icons {
    justify-content: space-between !important;
    display: flex;
}

.upload-sample-csv {
    float: right;
    color: blue;
    cursor: pointer;
}

.send-email-alert-checkbox{
    padding-top: 14px;
}

/* upload attactment loading  */
.uploaded-images-col.attactment-successfull-msg {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
}
.uploaded-images-col.attactment-successfull-msg p{
color: green;
margin: 0;
}
.show-loading {
display: flex;
align-items: center;
justify-content: space-between;
}
/*  */

.css-1jqq78o-placeholder {
    grid-area: 1/1/2/3;
    color: hsl(0deg 0% 61.56%);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-size: 12px;
}

p.duplicate-error-email {
    color: rgb(164, 38, 44);
    font-size: 13px;
    line-height: 22px;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

span.add-another-button {
    color: #2962FF;
}

.image-add-another-button {
    width: 100px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    cursor: pointer;
}

a.export-anchor-tag {
    border: 1px solid #2962FF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    width: 83px;
    background: transparent;
    color: #2962FF;
    padding: 0;
    margin-right: 10px;
}

a.export-anchor-tag:hover{
    background: rgb(240, 239, 239);
    font-weight: 400;
    color:black;
}

span.export-span {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

}

.delete-columns-List-Modal {
        overflow-y: scroll !important;
    max-height: 110px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #323130;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

/* Responsive Style */

@media only screen and (max-width: 1400px) {
    .add-user-form .form-group {
        width: 47%;
    }

    .fluent-table-v2 {
        padding: 20px 31px 0 31px;
        max-height: 500px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 1240px) {
    .links-col {
        padding-right: 15px;
    }

    .links-col a {
        font-size: 13px;
    }

    .add-user-form .form-group {
        width: 46%;
    }

    .form-editor {
        width: 97%;
        float: left;
    }

    .header-links {
        padding-left: 30px;
    }

    .user-tabs span.ms-Pivot-text {
        font-size: 16px;
    }


}

@media only screen and (max-width: 767px){

    .input-with-label.file-uploader {
        float: left;
        margin-bottom: 40px;
    }

    .registration-form-remark-field textarea{
        padding:6px 0 0 12px !important;
    }
}


.registtration-form-content .form-para{
    margin:0;
}

.registtration-form-content .form-para span{
    font-size: 15px;
}
.input-with-label.file-uploader {
    float: left;
    width: 100%;
    margin-bottom: 13px;
}
@media only screen and (max-width: 767px){
    .input-with-label.file-uploader {
        float: left;
        margin-bottom: 40px;
    }
    .registration-form-remark-field textarea{
        padding:6px 0 0 12px !important;
    }
    .registration-form-remark-field{
        margin-top: 0;
    }
}