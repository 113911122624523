@media only screen and (max-width: 1600px) {
    
    .unsubscribe-box.new{
        height:100vh !important;
    }
}
@media only screen and (max-width: 1200px) {
    .new-edit-section {
        width: 56%;
        float: left;
    }

    .profile-pic-box {
        height: 137px;
    }
    .home-left-col {
        width: 23%;
        float: left;
        position: fixed;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1170px !important;
        margin: 0 auto;
    }

    .mobile-search {
        display: none;
    }

    .mobile-dot {
        display: none;
    }

    .footer {
        display: none;
    }
}

@media only screen and (max-width: 920px) {
    .receive-conversation-display .calout-boxes {
        max-width: 223px;
    }

    .type-box textarea {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .header {
        border: 1px solid #EEEEEE;
    }

    .header-main {
        height: 67px;
    }

    .header-search {
        display: none;
    }

    .header-col-left {
        padding-left: 19px;
    }

    .header-col-right {
        padding-right: 19px;
        width:auto;
    }

    .header-col-left .dlogo {
        display: none;
    }

    .mlogo {
        display: flex;
        align-items: center;
    }

    .header-col-left a {
        text-decoration: none;
    }

    .mlogo img {
        height: 45px;
    }

    .profile-pic {
        display: none;
    }

    .mlogo span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 8px;
        display: block;
    }

    .mobile-search {
        cursor: pointer;
        display: block;
        margin-right: 6px;
    }

    .mobile-dot {
        cursor: pointer;
        display: block;
    }

    .home-left-col {
        display: none;
        position: relative;
    }

    .home-right-col {
        display: none;
    }



    .user-info img {
        display: none;
    }
    .mobile-dot img{
        display: block ;
        width:24px;
    }
    .notification {
        display: none;
    }

    .home-body {
        background: #ffffff;
        padding-bottom: 66px;
    }

    .sorting img {
        width: 60%;
    }

    .card {
        margin-bottom: 15px;
    }
    .card-header-new{
        padding: 0 15px;
    }
    /* Footer */
    .footer {
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.07);
        height: 66px;
        display: none;
    }

    .footer-main ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .footer-main ul li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
    }

    .footer-main ul li a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        height: 66px;
        justify-content: center;
    }

    .footer-main ul li a span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #666666;
        font-family: 'Calibri';
    }

    .footer-main ul li a img {
        width: 32px;
        margin: 0 auto 3px auto;
    }

    .edit-card-top {
        margin-top: 30px;
    }

    .new-edit-section {
        width: 100%;
        float: left;
        padding: 0;
    }

    .profile-pic-box {
        width: 100%;
        float: left;
        height: auto;
        margin-right: 0px;
        margin-top: 20px;
        padding: 20px 0;
    }
    .unsubscribe-content{
        width:100% !important;
    }
    .unsubscribe-content .for-unsubscribe-mobile{
        padding: 0 20px;
    }
    .usser-name{
        display: none;
    }
    .edit-profile-modal .close-modal {       
        right: -8px;
        top: 10px;
    }
    .unsubscribe-box{
        padding: 20px 0;
        height:100% !important;
    }
    .checkbox-group-unsubscribe {
        display: flex;
        flex-direction: column;
        width: auto !important;
        padding: 0 20px !important;
    }
    .unsubsscri-button{
        margin-left: 20px !important;
    }
    .notfound-container{
        flex-direction: column;
    }
    .not-found-left{
        width:auto;
        padding: 20px 15px;
    }
    .not-found-right{
        width:auto;
    }

    .not-found-right img{
        width:100%;
    }
    .options-card p.days-left{
        width:100% !important; 
    }
    .add-user-form .form-group {
        width: 100% !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .registtration-form {
        width: 95% !important;
        float: left;
    }
    .registtration-form-content{
        width:95% !important;
    }
    .form-page{
        margin-top:20px !important;
    }
    .next-button button{
        width:150px !important;
    }
}