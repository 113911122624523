.darkColor{
 color:#212121;
}

.blueColor{
    color:#2962FF;
}

.lightColor{
    color: #6B7280;
}

.darkLightColor{
    color:#424242;
}

.headingColor{
    color: rgba(0, 32, 51, 0.6)
}

.greenColor{
    color:#00C853;
}

.greyLight{
    color: #6F727A;
}

.navColor{
    color: #9E9E9E;
}

.filtercolor{
    color: #757575;
}

.fontFamily{
    font-family: 'Calibri', serif;
}

.line-height20{
    line-height: 20px;
}

.line-height24{
    line-height: 24px;
}

.line-height120{
    line-height: 120%;
}

.line-height150{
    line-height: 150%;
}

.line-height110{
    line-height: 110%;
}

.line-height100{
    line-height: 100%;
}

.line-height28{
    line-height: 28px;
}

.line-height17{
    line-height: 17px;
}

.float{
    float:left;
}

.width100{
    width:100%;
}

.margin{
    margin:0;
}
.marginBottom{
    margin-bottom: 0;
}
.bottom15{
    margin-bottom: 15px;
}
.marginTop{
    margin-top:14px;
}
.dblock{
    display: block;
}

.pl{
    padding-left: 20px;
}
.pr{
    padding-right: 20px;
}
