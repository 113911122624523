body {

    font-family: 'Calibri';
    font-weight: normal;
    font-style: normal;
    background: #f3f2ef !important;
}

.footer {
    display: none;
}

.screens {
    width: 100%;
    background: #f3f2ef;
    float: left;
}

/* User Login Style */
.mlogo {
    display: none;
}

.login-full-view {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.login-full-view .form {
    background: #FFFFFF;
    border-radius: 4px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 33px 50px 40px 50px;
}

.logo {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 25px;
}

.logo img {
    width: auto;
}

/* Form Style */
.form-fields {
    margin-top: 25px;
    width: 100%;
}

.simple-input {
    margin-bottom: 20px;
}

.form-control {
    background: #FFFFFF;
    border: 1px solid #757575;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #494949;
    text-indent: 30px;
    font-family: 'Calibri';
}

.or {
    width: 100%;
    text-align: center;
    margin: 19px 0;
    color: #BABABA;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.fb-button {
    width: 100%;
    background: #1877F2;
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.fb-button img {
    margin-right: 15px;
}

.fb-button button {
    border: none;
    background: transparent;
    height: 50px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Calibri';
}

.google-button {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    cursor: pointer;
}

.google-button img {
    margin-right: 15px;
}

.google-button button {
    border: none;
    background: transparent;
    height: 50px;
    color: #212121;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Calibri';
}

.apple-button {
    width: 100%;
    background: #42A5F5;
    border-radius: 6px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    cursor: pointer;
}

.apple-button img {
    margin-right: 15px;
}

.apple-button button {
    border: none;
    background: transparent;
    height: 50px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Calibri';
}

/* Header Style */
.header {
    width: 100%;
    background: #ffffff;
    display: block;
    z-index: 99;
    position: fixed;
    top: 0;
}

.header-main {
    width: 100%;
    height: 53px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.header-col-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}

.header-col-left a {
    display: flex;
}

.header-col-left .dlogo {
    display: flex;
    padding: 7px 0;
}

.header-col-left a.dlogo img {
    height: 40px;
}

.header-search {
    background: #EEF3F8;
    border-radius: 5px;
    height: 36px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
    width: 314px;
}

.header-search img {
    margin-right: 15px;
}

.header-search input {
    border: none;
    background: transparent;
    height: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #737373;
    outline: none;
    width: 100%;
    font-family: 'Calibri';
}

.header-col-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}

.user-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.notification {
    padding-right: 23px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-pic {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
}

.profile-pic img {
    border-radius: 100px;
    margin-right: 7px;
    width: 24px;
    height: 24px;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info img {
    margin-left: 5px;
}

/* Home Style */

.home-body {
    background: #f3f2ef;
    height: calc(100% - 30px);
    width: 100%;
    padding-top: 60px;
}

.home-left-col {
    width: 275px;
    float: left;
    position: fixed;
}

.user-info-col {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #E3F2FD;
    border-radius: 8px 8px 0px 0px;
    padding: 10px 12px;
}

.pic-card {
    display: flex;
    margin-right: 10px;
    width: 72px;
    height: 72px;
}

.pic-card img {
    width: 72px;
    border-radius: 100px;
    height: 72px;
}

.user-info-col span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
}

.user-info-col p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9);
}

.profile-col span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
}

.profile-col p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9);
}

.links-card {
    width: 100%;
    float: left;
    background: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.links-card ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.links-card ul li {
    margin: 0;
    padding: 0;
}

.links-card ul li a {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    background: transparent;
    padding: 0 15px;
}

.links-card ul li a:hover {
    background: #e0e0e05c;
}

.links-card ul li a:hover span {
    opacity: 1;
}

.links-card ul li a span {
    color: #414141;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-left: 20px;
    display: block;
}

.branch-member-card {
    background: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    width: 100%;
    float: left;
    margin-top: 20px;
}

.memer-heading {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.memer-heading span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Calibri', serif;
}

.member-list {
    padding: 0 22px;
}

.member-list a {
    text-decoration: none !important;
}

.member-list-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
}

.col-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.member-pic {
    width: 48px;
    height: 48px;
    display: flex;
}

.member-pic img {
    width: 48px;
    border-radius: 100px;
    height: 48px;
}

.member-info {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.member-info span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Calibri', serif;
}

.member-info p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
    font-family: 'Calibri', serif;
}

.col-right {
    cursor: pointer;
}

.view-all-member {
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.view-all-member a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.view-all-member a span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.6);
}

.view-all-member a img {
    margin-left: 8px;
}

.banner-col-right {
    width: 100%;
    float: left;
    margin-top: 20px;
}

.banner-col-right img {
    width: 100%;
}

.member-list-right {
    padding: 0 15px;
}

.member-list-col-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.member-info-new {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
}

.member-info-new span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9);
}

.member-info-new p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.6);
    margin: 0;
}

.home-middle-col {
    width: 100%;
    float: left;
    height: 410px;
}

.card {
    background: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    margin-bottom: 10px;
    float: left;
    width: 100%;
}

.card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
}

.card-header-col-left {
    display: flex;
    align-items: flex-start;
}

.card-header-col-left img {
    width: 36px;
    height: 36px;
}

.post-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.post-info h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.post-info span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.6);
}

.date-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.date-info span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.6);
}

.header-col-right {
    cursor: pointer;
}

.card-description {
    background: #ffffff;
    padding: 15px;
}

.card-description p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.card-description a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0A66C2;
}

.post-image {
    width: 100%;
}

.post-image img {
    width: 100%;
}

.post-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0px;
    width: 100%;
}

.like-action {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 33.3%;
    padding: 6px 0;
}

.like-action:hover {
    background: #e0e0e07d;
}

.like-action span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135.69%;
    color: #606770;
    margin-left: 15px;
    display: block;
}

.like-pic {
    display: flex;
    align-items: center;
}

.like-action img {
    width: 18px;
    height: 18px;
}

.after-like-action {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 33.3%;
    padding: 6px 0;
}

.after-like-action span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135.69%;
    color: #42A5F5;
    margin-left: 15px;
    display: block;
    padding-top: 5px;
}

.after-like-action img {
    width: 18px;
    height: 18px;
}

.show-comment {
    width: 100%;
}

.comment-heading {
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 25px;
    border-top: 1.26425px solid #F2F2F2;
    border-bottom: 1.26425px solid #F2F2F2;
    cursor: pointer;
}

.comment-heading span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.all-commnets {
    width: 100%;
    padding-top: 22px;
}

.comments-col {
    display: flex;
    flex-direction: column;
    padding: 0px 25px 20px 25px;
}

.comment-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.from-message {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.from-message span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.from-message p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(102, 102, 102, 0.6);
    margin: 0;
}

.comment-description {
    margin-top: 10px;
}

.comment-description p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #272727;
    margin: 0;
}

.comments-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0;
}

.comment-action-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.like-count {
    margin-right: 30px;
}

.like-count span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #606770;
}

.comment-count {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.comment-count span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #606770;
    margin-left: 7px;
    display: block;
}

.thread {
    border-left: 1.79px dashed #E0E0E0;
    padding: 30px 0;
}

.add-post {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    float: left;
    margin-bottom: 22px;
}

.post-adding {
    padding: 18px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.post-pic {
    width: 46px;
    height: 46px;
    display: flex;
    margin-right: 20px;
}

.post-pic img {
    width: 46px;
    border-radius: 100px;
}

.post-options {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.post-input {
    width: 99%;
}

.post-input input {
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    height: 46px;
    color: #424242;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-indent: 12px;
    outline: none;
    padding: 0;
    width: 100%;
    font-family: 'Calibri';
}

.post-input input::-webkit-input-placeholder {
    /* Edge */
    color: #BDBDBD;
}

.post-input input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #BDBDBD;
}

.post-input input::placeholder {
    color: #BDBDBD;
}

.post-media-options {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-videos {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.post-videos span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #66676B;
    margin-left: 7px;
    display: block;
}

.sorting-section {
    width: 100%;
    float: left;
}

.sorting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
}

.sorting img {
    padding-right: 10px;
    display: block;
}

.sorting span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.9);

}

.sorting-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.sorting-dropdown span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.9);
    margin-right: 15px;
    display: block;
}

.sorting-dropdown img {
    padding: 0;
}

.card-header-col-right {
    cursor: pointer;
}

/* Edit Profile Style */
.editprofile-pic {
    width: 70px;
    height: 70px;
}

.editprofile-pic img {
    width: 70px;
    border-radius: 100px;
    height: 70px;
}

.card-header-new {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0px;

}

.card-header-col-left-new {
    display: flex;
    align-items: center;
}

.post-info-new {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.post-info-new h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.post-info-new span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.post-info-new p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin: 0;
}

.edit-card-top {
    padding: 15px 20px;
    background: #ffffff;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
}

.edit-card-top p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #424242;
    margin: 0;
}

.card-header-col-right-new {
    cursor: pointer;
}

.education-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
}

.education-heading span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.education-heading img {
    margin-left: 10px;
    width: 31px;
    cursor: pointer;
}

.col-right img {
    width: 31px;
}

.education-list {
    padding: 0 20px;
}

.member-list-col-new {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 19px;
    border-top: 1px solid #EEEEEE;
    padding-top: 20px;
}

.card-header-col-right-new img {
    width: 24px;
}

.member-info-new-details {
    display: flex;
    flex-direction: column;
}

.member-info-new-details h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.member-info-new-details span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
}

.member-info-new-details p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin: 0;
}

.new-text {
    line-height: 32px !important;
}

.comment-action-right img {
    cursor: pointer;
}

/* Modal Style */
.title-123 {
    display: none;
}

.main-111 {
    border: 1px solid #DEE2E6;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

/* Messages Style */
.messages-screen {
    width: 100%;
    background: #e0e0e07d;
    float: left;
}

.chat-left {
    padding: 0;
}

.chat-right {
    padding: 0 3px;
}

.all-messages {
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
    box-shadow: 0px -8px 32px rgba(70, 96, 135, 0.1);
    border-radius: 8px;
}

.messages-search-head {
    padding: 18px 18px 10px 18px;
}

.messages-search {
    background: #F5F5F5;
    border-radius: 10px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.messages-search .search-input {
    border: none;
    outline: none;
    height: 46px;
    color: #737373;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    background: transparent;
    margin-left: 15px;
    width: 100%;
}

.messages-tabs {
    padding: 5px 8px 15px 8px;
}

.messages-tabs .ms-Button {
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(70, 96, 135, 0.1);
    border-radius: 4px;
    height: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1B1A57;
    padding-bottom: 3px;
}

.messages-tabs .linkIsSelected-108::before {
    display: none;
}

.messages-tabs .linkIsSelected-108::after {
    display: none !important;
}

.messages-tabs .ms-Pivot {
    padding: 0 10px;
}

.messages-tabs .linkIsSelected-108 {
    background: #42A5F5 !important;
    border-radius: 4px;
    height: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
}

.chat-list {
    padding-top: 15px;
    height: calc(70vh - 30px);
    overflow-y: auto;
}

.conversation-messages {
    padding: 5px 8px;
    background: transparent;
    border-radius: 8px;
}

.conversation-messages.selected {
    padding: 5px 8px;
    background: #42a5f51f;
    border-radius: 8px;
}

.conversation-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 64px;
}

.list-user-pic {
    width: 48px;
    height: 48px;
    position: relative;
}

.list-user-pic img {
    width: 48px;
    border-radius: 100px;
}

.user-online {
    position: absolute;
    bottom: -5px;
    right: 0;
}

.user-online img {
    width: 12px;
}

.list-messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 15px;
}

.list-user-col-up {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-user-col-up h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #424242;
    margin: 0;
}

.list-user-col-up span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}

.list-user-col-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-user-col-down p {
    margin: 0;
    color: #616161;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.converstion-chat {
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
    border-radius: 8px;
}

.converstion-header .card-header {
    align-items: center;
    padding: 0px 25px;
    height: 91px;
    border-bottom: 1px solid #EDEDED;
}

.converstion-header .card-header-col-left {
    display: flex;
    align-items: center;
}

.converstion-header .card-header-col-right img {
    height: 60px;
}

.converstion-header .post-info h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1B1A57;
}

.converstion-header .post-info span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #4F5E7B;
}

.messages-conversation {
    width: 100%;
    height: calc(100% - 31px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.inner-messages {
    float: left;
    width: 100%;
    padding: 15px 0;
    height: calc(70vh - 70px);
    overflow-y: auto;
}

.messages-body {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 20px;
}

.sent-messages {
    width: auto;
    height: auto;
}

.conversation-display {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.calout-boxes {
    background: #42A5F5;
    border-radius: 8px 8px 0px 8px;
    width: auto;
    max-width: 287px;
    display: flex;
    flex-direction: column;
    padding: 8px 13px;
}

.calout-boxes span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 6px;
    display: block;
}

.calout-boxes p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin: 0;
    text-align: right;
}

.calout-intial {
    margin-left: 8px;
    width: 40px;
    height: 40px;
}

.calout-intial img {
    width: 40px;
    border-radius: 100px;
}

.messages-reply-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-bottom: 30px;
}

.receive-messages {
    width: auto;
    height: auto;
    display: flex;
    flex-flow: wrap-reverse;
    justify-content: flex-end;
}

.receive-messages .calout-intial {
    margin-right: 8px;
}

.receive-conversation-display {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.receive-conversation-display .calout-boxes {
    background: #F5F5F5;
    border-radius: 8px;
    width: auto;
    max-width: 369px;
    display: flex;
    flex-direction: column;
    padding: 8px 13px;
}

.receive-conversation-display .calout-boxes h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #42A5F5;
    margin-bottom: 6px;
    display: block;
    margin: 0 0 10px 0;
}

.receive-conversation-display .calout-boxes span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1B1A57;
    margin-bottom: 6px;
    display: block;
}

.receive-conversation-display .calout-boxes p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #A1A1BC;
    margin: 0;
    text-align: right;
}

/* Input Tool bar Style */
.converstion-input-tool {
    float: left;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px;

}

.tool-bar {
    display: flex;
    padding: 0 20px;
    align-items: center;
    height: 66px;
    justify-content: space-between;
}

.typing-bar {
    display: flex;
    align-items: center;
}

.action-bar {
    display: flex;
    align-items: center;
}

.smiley {
    display: flex;
    padding-right: 0px;
}

.type-box textarea {
    border: none;
    outline: none;
    background: transparent;
    color: #1B1A57;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 370px;
    text-indent: 20px;
    resize: none;
    padding: 10px 0 0 0;
}

.send-chat-buton {
    display: flex;
    padding-left: 0px;
}

.send-chat-buton button {
    border: none;
    background: transparent;
    padding: 0;
}

.attchment-box {
    display: flex;
    padding-right: 10px;
}

/* Edit Profile Modal */

.edit-profile-modal {
    padding: 20px 30px 0 30px;
}

.edit-profile-modal .close-modal {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    width: 100%;
    position: absolute;
    right: -19px;
    top: 4px;
}

.edit-profile-modal h4 {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    color: #54595E;
}

.edit-form {
    width: 100%;
    float: left;
    margin: 20px 0;
}

.edit-form .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.edit-form label {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #252B42;
    margin-bottom: 8px;
    padding: 0;
}

.edit-form input {
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    outline: none;
    text-indent: 10px;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #737373;
}

.edit-form textarea {
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    height: 110px;
    width: 100%;
    outline: none;
    text-indent: 15px;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #737373;
    padding-top: 15px;
}

.edit-form span.ms-Dropdown-title {
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    text-indent: 15px;
    display: flex;
    align-items: center;
    box-shadow: none;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #737373 !important;
    outline: none;
}

.edit-form span.ms-Dropdown-title:hover {
    border: 1px solid #E6E6E6;
}

.edit-form .drop-with-icon img {
    position: absolute;
    right: 15px;
    top: 41px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.form-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

}

.form-buttons .cancel {
    background: transparent;
    border: 1px solid #2962FF;
    border-radius: 4px;
    height: 44px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2962FF;
    cursor: pointer;
    margin-right: 16px;
    padding-bottom: 5px;
}

.form-buttons .cancel:hover {
    background: rgb(243, 242, 241);
}

.form-buttons .save {
    background: #2962FF;
    border: 1px solid #2962FF;
    border-radius: 4px;
    height: 44px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    padding-bottom: 5px;
}

.form-buttons .save:hover {
    background: #3654a5;
}

.user-drop {
    background: linear-gradient(134.47deg, #FFFFFF -26.79%, #D1D1D1 230.75%);
    box-shadow: 2px 8px 24px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 43px;
    right: 2px;
    width: 152px;
    border-radius: 8px;
    z-index: 9999;
}

.user-drop ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.user-drop ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
}

.user-drop ul li a:hover {
    background: #ffffff;
}

.user-drop ul li a span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #424242;
}

.user-drop ul li a img {
    margin-right: 12px;
    width: 24px;
}

.sorting-drop {
    background: #ffffff;
    position: absolute;
    top: 29px;
    right: -2px;
    width: 100px;
    border-radius: 0px 0px 8px 8px;
    z-index: 9999;
    border: 1px solid #3333331f;
    box-shadow: 2px 2px 11px rgb(0 0 0 / 16%);
}

.sorting-drop ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.sorting-drop ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 9px 12px;
}

.sorting-drop ul li a:hover {
    background-color: #e0e0e07d;
}

.sorting-drop ul li a span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #424242;
}

.sorting-drop ul li a img {
    margin-right: 12px;
}

/* Upload Profile Pic */
.new-edit-section {
    width: 61%;
    float: left;
    height: 106px;
    display: flex;
    align-items: center;
}

.profile-pic-box {
    background: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    width: 276px;
    float: left;
    height: 137px;
    margin-right: 15px;
}

.upload-profile-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 15px;
    cursor: pointer;
}

.upload-box {
    border-radius: 100px;
    width: 50px;
    height: 50px;
    background: #42A5F5;
    border: 1px solid #42A5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-box img {
    width: 32px;
}

.upload-profile-pic span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    text-align: center;
    padding-top: 5px;
}

.profile-save-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 15px;
}

.uploaded-image {
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.uploaded-image img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
}

.profile-actions {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-actions .cancel {
    width: 70px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #4F4F4F;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #4F4F4F;
    margin-right: 15px;
    cursor: pointer;
}

.profile-actions .save {
    width: 70px;
    height: 32px;
    background: #4F4F4F;
    border: 1px solid #4F4F4F;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}

.remove-image {
    border-radius: 100px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.remove-image img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    position: relative;
}

.profile-actions a {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #42A5F5;
    text-decoration: none;
}

.edit-overlay {
    position: absolute;
    bottom: -4px;
    right: 2px;
    cursor: pointer;
}

.edit-overlay img {
    width: 18px;
    height: 18px;
}

/* .ms-Dialog-header {
    display: none;
} */
.ms-Dialog-header .ms-Dialog-title {
    font-size: 0px;
    padding: 0;
}

.edit-form span {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    margin: 0;
    color: #54595E;
}

.post-collage-view .hXkfgD {
    border: none;
}

.post-collage-view .hLpbFi {
    border: none;
}

.links-card ul li a .active-icon {
    display: none;
}

.links-card ul li.nav-item.active a .inactive-icon {
    display: none;
}

.links-card ul li.nav-item.active a .active-icon {
    display: flex;
}

.links-card ul li.nav-item.active a span {
    color: #0F56B3;
}

.links-card .nav-icons {
    display: flex;
}

.links-card .nav-icons img {
    width: 24px;
}

.education-checkbox {
    flex-direction: row !important;
    align-items: center;
}

.education-checkbox label {
    margin-left: 10px;
    margin-bottom: 0;
}

.education-checkbox input {
    width: 20px;
    height: 20px;
}

.update-form {
    padding: 15px 20px;
}

.update-form .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.update-form label {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #252B42;
    margin-bottom: 8px;
    padding: 0;
}

.update-form input {
    background: #F9F9F9;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    height: 36px;
    outline: none;
    text-indent: 10px;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #737373;
}

.update-form .form-group span {
    color: rgb(164, 38, 44) !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: 'Calibri', serif;
    font-weight: 400;
}

.update-form .form-buttons {
    justify-content: flex-end;
}

.update-form .form-buttons .cancel {
    background: #FFFFFF;
    border: 1px solid #2962FF;
    border-radius: 4px;
    height: 36px;
    padding: 0 10px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2962FF;
    cursor: pointer;
    margin-right: 16px;
}

.update-form .form-buttons .cancel:hover {
    background: rgb(243, 242, 241);
}

.update-form .form-buttons .save {
    background: #2962FF;
    border: 1px solid #2962FF;
    border-radius: 4px;
    height: 36px;
    padding: 0 10px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}

.update-form .form-buttons .save:hover {
    background: #3654a5;
}

.editorClassName {
    min-height: 380px;
}

/* Not Found Style */
.not-found {
    width: 100%;
    float: left;
    padding-top: 60px;
}

.notfound-container {
    background: #FAFAFA;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.not-found-left {
    width: 30%;
    padding: 30px;
}

.not-found-left h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #000000;
    margin: 0 0 13px 0;
}

.not-found-left span {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
    margin-bottom: 17px;
    display: block;
}

.not-found-left p {
    margin: 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
}

.not-found-right {
    width: 70%;
    margin: 0 auto;
    text-align: center;
}

.not-found-right img {
    width: auto;
}

.go-home-button {
    margin-top: 30px;
}

.go-home-button a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0A66C2;
    border-radius: 5px;
    height: 42px;
    width: 200px;
}

.go-home-button a img {
    margin-right: 12px;
}

.go-home-button a span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
    line-height: 100% !important;
}

.description-contactUs-form
{
    resize: vertical !important;
    background: rgb(249, 249, 249);
    border: 1px solid rgb(230, 230, 230);
    border-radius: 5px;
    height: 165px;
    outline: none;
    text-indent: 10px;
    font-family: Calibri;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: rgb(115, 115, 115);
}


/* --------------frontend side Home welcome Alumni----------------*/
.welcome-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.welcome-msg h1{
    font-size: 45px;
    margin-bottom: 12px;
}
.welcome-msg p{
    margin: 0;
    font-size: 20px;
}

/* manage queries sory by dropdown */
.hello .dropdown-154:focus::after {
    border: none !important;
}
.hello .dropdown-116:focus::after {
    border: none;
}
